<template>
  <div class="wrapper overflow-y-auto">
    <div class="wrapper__header">
      <div class="header__title">
        {{ $t('addCompany') }}
      </div>
      <div class="header__close">
        <add-company-icon @click.native="$emit('close')" />
      </div>
    </div>
    <div class="wrapper__body">
      <div class="body-field-wrapper">
        <div class="body-field">
          <div class="field__title">
            {{ $t('loginPage.nameCompany') }}
          </div>
          <el-input
            required
            v-model="registerForm.nameCompany"
            type="text"
            :class="{ 'input-error': isCompanyNameError }"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isCompanyNameError"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('loginPage.errors.nameCompany') }}
            </div>
          </transition>
        </div>
        <div class="body-field">
          <div class="field__title">
            {{ $t('loginPage.timeZone') }}
          </div>
          <el-select v-model="registerForm.tz" class="login-form-select">
            <el-option
              v-for="item in tzList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </div>
        <div class="body-field">
          <div class="field__title">IMEI</div>
          <div class="field__description">
            {{ $t('addCompanyTransalter.description') }}
          </div>
          <el-input
            v-model="registerForm.imei"
            type="textarea"
            maxlength="2000"
            :rows="5"
          />
          <el-checkbox
            class="checkbox"
            v-model="isSelectCompany"
            :value="isSelectCompany"
          >
            {{ $t('addCompanyTransalter.selectCompany') }}
          </el-checkbox>
        </div>
      </div>
      <button class="primary" @click="saveCompany">
        {{ $t('addCompanyTransalter.save') }}
      </button>
    </div>
    <div class="wrapper__add-user">
      <div class="title">
        <span
          :class="['title__add', { 'title__add--is-add': isOpenForm }]"
          @click="openForm"
        >
          {{ $t('addCompanyTransalter.addUser') }}
        </span>

        <span
          :class="['title__cancel', { 'title__cancel--is-cancel': isOpenForm }]"
          @click="isOpenForm = false"
        >
          {{ $t('addCompanyTransalter.cancel') }}
        </span>
      </div>
      <div :class="['form', { 'form--is-open': isOpenForm }]">
        <div class="body-field">
          <div class="field__title">{{ $t('loginPage.userName') }}:</div>
          <el-input
            required
            v-model="registerForm.name"
            type="text"
            :class="{ 'input-error': isNameError }"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isNameError"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('loginPage.errors.nameCompany') }}
            </div>
          </transition>
        </div>
        <div class="body-field">
          <div class="field__title">Email:</div>
          <el-input
            required
            v-model.trim="registerForm.userProviderId"
            type="text"
            :class="{ 'input-error': isNameError }"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isNameError"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('loginPage.errors.nameCompany') }}
            </div>
          </transition>
        </div>
        <div class="body-field">
          <div class="field__title">{{ $t('loginPage.errors.pass') }}</div>
          <el-input
            required
            v-model.trim="registerForm.password"
            type="text"
            :class="{ 'input-error': isNameError }"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isNameError"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('loginPage.errors.nameCompany') }}
            </div>
          </transition>
        </div>
        <div class="body-field">
          <div class="field__title">
            {{ $t('loginPass.changePass.repeatNewPass') }}
          </div>
          <el-input required v-model.trim="repeatPassword" type="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { companyApi } from '@/api'

import AddCompanyIcon from '@/components/admin-panel/components/assets/icons/CloseIcon.vue'
import PlusIcon from '@/components/admin-panel/components/assets/icons/PlusIcon.vue'
import ElementLocale from 'element-ui/lib/locale'
import localeEn from 'element-ui/lib/locale/lang/en.js'
import localeRu from 'element-ui/lib/locale/lang/ru-RU.js'

export default {
  name: 'Add-Company',
  components: {
    AddCompanyIcon,
    PlusIcon
  },

  data() {
    return {
      isCompanyNameError: false,
      isNameError: false,
      registerForm: {
        nameCompany: '',
        tz: '',
        imei: '',
        name: '',
        userProviderId: '',
        type: 'email',
        password: ''
      },
      repeatPassword: '',
      isSelectCompany: true,
      isOpenForm: false
    }
  },

  computed: {
    ...mapState({
      tzList: (state) => state.properties.timezones
    }),
    ...mapState('login', {
      me: (state) => state.me
    })
  },

  methods: {
    ...mapActions({
      addCompany: 'dealers/createDealer',
      getTerminals: 'terminal/getTerminals',
      getPorts: 'ports/getPorts',
      getMe: 'login/getMe'
    }),

    openForm() {
      this.isOpenForm = !this.isOpenForm
    },

    async saveCompany() {
      if (!this.registerForm.nameCompany) {
        return this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('addCompanyTransalter.error.name')
        })
      }

      if (this.registerForm.tz === '') {
        return this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('addCompanyTransalter.error.time')
        })
      }

      if (this.registerForm.password !== this.repeatPassword) {
        return this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('addCompanyTransalter.error.password')
        })
      }

      if (this.isOpenForm) {
        const regexpEmail = /^[^@\s]+@[^@\s]+.[^@\s]+$/

        if (this.registerForm.name.length === 0) {
          return this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: this.$t('addCompanyTransalter.error.enterName')
          })
        }

        if (
          !regexpEmail.test(this.registerForm.userProviderId) ||
          this.registerForm.userProviderId.length === 0
        ) {
          return this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: this.$t('addCompanyTransalter.error.enterEmail')
          })
        }

        if (this.registerForm.password.length === 0) {
          return this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: this.$t('addCompanyTransalter.error.enterPassword')
          })
        }

        if (this.repeatPassword !== this.registerForm.password) {
          return this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: this.$t('addCompanyTransalter.error.repeatPassword')
          })
        }
      }

      const data = {
        timezone: {
          key: this.registerForm.tz
        },
        company_name: this.registerForm.nameCompany,
        imeis: this.registerForm.imei.split('\n')
      }

      if (this.registerForm.userProviderId) {
        data.userProviderId = this.registerForm.userProviderId
        data.password = this.registerForm.password
        data.type = 'EMAIL'
        data.name = this.registerForm.name
      }

      const company = await this.addCompany(data)

      if (company.status !== 200) {
        return this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: company.data.message || 'error'
        })
      }

      if (this.isSelectCompany) {
        this.changeCompany(company.data.id)
      }

      this.getMe()
      this.$emit('close', company.data)
    },

    changeCompany(companyID) {
      const component = this
      companyApi.changeCompany(
        companyID,
        function (response) {
          component.getAllRequest().then(() => {
            component.isLoading = false
            // component.$store.dispatch('geozones/GET_GEOZONES')
            component.$store.commit('login/COMPANY_CHANGED')
          })
        },
        function (error) {
          console.log(error, 'error')
        }
      )
      // localStorage.setItem('filtredUnits', JSON.stringify(null))
      this.$nextTick(() => {
        this.$store.commit('units/RESET_FILTRED_UNITS')
      })
      this.$store.dispatch('reports/SAVE_CURRENTREPORT', null)
    },

    async getAllRequest() {
      this.$store.commit('login/setLoading')
      this.spinnerLoading = true
      await this.$store.dispatch('login/getMe').then((res) => {
        this.$i18n.set(this.me.lang.key)
        const locale = this.me.lang.key === 'ru' ? localeRu : localeEn
        ElementLocale.use(locale)
        if (
          res.active_company.billing &&
          res.active_company.billing.tariff.price !== 0
        ) {
          if (res.active_company.billing.company_status === 'BLOCKED') {
            this.isNavigation = false

            this.isCompanyBlocked = true
            this.$notify.error({
              message: this.$t('tariff.blocked-info'),
              duration: 0,
              showClose: false,
              customClass: 'blockedCompany',
              position: 'top-left'
            })
          } else {
            this.isNavigation = true
          }
        } else {
          this.isNavigation = true
        }
      })
      await this.$store.dispatch('properties/GET_ACCESS_RIGHTS')
      await this.$store.dispatch('properties/getAllProperties')

      await this.$store.dispatch('users/GET')
      await this.$store.dispatch('trailers/GET')
      await this.$store.dispatch('mailings/GET')
      await this.$store.dispatch('races/GET_RACES', { from: 0, count: 100 })
      await this.$store.dispatch('routes/GET_ROUTES', { from: 0, count: 100 })
      Promise.all([
        this.$store.dispatch('reports/GET_TEMPLATES'),
        this.$store.dispatch('units/GET_UNITS'),
        this.$store.dispatch('units/GET_GROUP_UNITS')
      ]).then((result) => {
        if (this.isReport) this.$refs.report.setFormLocalStorage(result)
      })
      await this.$store.dispatch('properties/CUSTOM_FIELDS')
      await this.$store.dispatch('dictionary/getParamsUnitSensor')

      await this.$store.dispatch('dictionary/getAllDictionary')

      await this.$store.dispatch('notifications/GET')

      // пока заккоментировал
      // this.getCacheNotification()

      this.$store.commit('login/setLoading')
      this.$nextTick(() => (this.spinnerLoading = false))
      await this.getTerminals({})
      await this.getPorts()
    }
  },

  mounted() {
    this.registerForm.tz = this.me.active_company.timezone.key
  }
}
</script>

<style lang="stylus" scoped>
.body-field
  margin-bottom 14px
.field
  &__title
    margin-bottom 12px
    color #91B4E7
    font-size 14px
    font-weight 600
    font-family Source Sans Pro
  &__description
    margin-bottom 14px
    font-weight 400
    font-size 12px
    color #677785

  .el-checkbox
    margin-top 30px
.primary
  height 35px
  padding 9px 25px
  background-color #5477A9
  font-size 14px
  line-height 16px
  font-weight 600
  color #fff
  border-radius 5px
  border none
  outline none
  transition all 0.3s
  &:hover
    background-color #3C5E8F

.wrapper

  position absolute
  top 0
  left 0
  bottom 0
  z-index 10
  width 600px
  padding-left 1.5rem
  padding-right 1rem
  padding-top 1.25rem
  background-color #fff
  box-shadow 0px 0px 10px rgba(0, 0, 0, 0.1)
  &__header
    display flex
    justify-content space-between
    .header
      &__title
        font-family Source Sans Pro
        font-size 20px
        font-weight 700
        color #5477A9
        line-height 25px
      &__close
        cursor pointer
  &__body
    display grid
    grid-template-columns 1fr 1fr
    grid-template-rows 1fr
    margin-top 30px
    gap 20px
  &__add-user
    min-height 230px
    overflow-y auto
    overflow-x hidden
    .title
      display flex
      justify-content space-between
      padding-top 20px
      margin-bottom 30px
      border-top 1px solid #DFE5EB
      font-family 'Lato'
      color #3C5E8F
      font-weight 700
      &__add, &__cancel
        cursor pointer
      &__cancel
        opacity 0.4
        pointer-events none
        &--is-cancel
          opacity 1
          pointer-events auto
      &__add
        &--is-add
          opacity 0.4
          pointer-events none
    .form
      display none
      &--is-open
        display flex
        flex-direction column
        height auto
        overflow-y auto
        @media(max-height: 800px) {
          height 160px
        }
      .save-user
        align-self flex-end
    .el-input
      width 304px
</style>
